/**

This component shows a read-only view of all values of a form result / form entry in a tabular format. This is a report meant for supervisors


**/

import React, {Component} from 'react';
import axios from 'axios';

import {
  Switch,
  Route,
  Link,
  useParams,
  useLocation,
  Redirect,
} from 'react-router-dom';

// v4 icons found here: https://v4.mui.com/components/material-icons/

import {Button, ButtonGroup, Container, List, ListItem, ListItemText, Grid} from '@material-ui/core';
import {Check as IconCheck} from '@material-ui/icons';
import {stringify} from 'querystring';

const API_PROTOCOL=process.env.REACT_APP_API_PROTOCOL;// 'https://';
const API_HOST=process.env.REACT_APP_API_HOST;// 'api.advancepsych.schedulekeep.com';

const HIDDEN_FORM_FIELDS = ['Form Response Edit URL', 'UUID', 'TGUUID', 'INIT', 'createTimestampMillis', 'createTimestamp', 'Timestamp', 'isShared'];

const REFRESH_INTERVALL_MILLIS = 1000 * 30;

let instance = null;
class UserForm extends React.Component {
  constructor() {
    super();

    this.state = {
      userForms: null,
    };
    instance = this;

    if (window.matchMedia) {
      const mediaQueryList = window.matchMedia('print');
      mediaQueryList.addEventListener('change', function(mql) {
        if (mql.matches) {
          instance.setState({xSmallColumns: 6, smallColumns: 4});
          // beforePrint();
        } else {
          instance.setState({xSmallColumns: 12, smallColumns: 6});
          // afterPrint();
        }
      });
    }
  }

  async callAPIAsync(path) {
    if (!instance.props.authUser) {
      return null;
    }
    try {
      const token = instance.props.authUser.getAuthResponse().id_token;
      const response = await axios.get(`${API_PROTOCOL}://${API_HOST}/${path}`, {
        headers: {
          'x-user-token': token,
        },
      });

      return response;
    } catch (err) {
      console.log('callAPIAsync: unable to complete call: '+err);
    }
  }

  async refreshSubordinateFormValues() {
    const formId = instance.state.currentFormId;
    const subordinateId = instance.state.currentSubordinateId;
    const submissionDate = instance.state.currentSubmissionDate;
    if (formId && formId !== this.state.lastFormId && subordinateId && subordinateId != this.state.lastSubordinateId) {
      instance.state.lastFormId = formId;
      instance.state.lastSubordinateId = subordinateId;
      const response = await this.callAPIAsync(`users/subordinateForms/${subordinateId}/forms/${formId}/submission/${submissionDate}`);
      if (response && response.data) {
        const formattedData = response.data;
        // console.log(`Resources returned from API: ${JSON.stringify(formattedData)}`);
        formattedData.createTimestamp = `${formattedData.createTimestampMillis ? new Date(parseInt(formattedData.createTimestampMillis)).toLocaleString() : new Date(formattedData.timestamp).toLocaleString()}`;

        instance.setState({formValues: formattedData});

        const formValuesArray = [];
        const keys = Object.keys(formattedData);

        keys.forEach((key)=>{
          const value = formattedData[key];
          const v = (value+'').toLowerCase().trim();
          const isCheckmark = v.startsWith('complete') || v == 'yes' || v == 'done';
          // console.log('question: '+key+' :: v: '+v+'. isCheckmark: '+isCheckmark);

          const q = (key+'').toLowerCase().trim();
          const areNotes = q.startsWith('notes');
          formValuesArray.push({itemName: key, value, isCheckmark, areNotes});
        });

        instance.setState({formValuesArray: formValuesArray});
      } else {
        instance.setState({formValues: {}});
        instance.setState({formValuesArray: []});
      }
    }
  }

  handleClick(e) {
    if (e && e.includes('http')) {
      window.open(e, '_blank');
    } else if (e === 'back') {
      window.history.back();
    } else if (e) {
      this.setState({redirectPath: e});
    }
  }

  async refresh() {
    await this.refreshSubordinateFormValues();
  }

  async componentDidMount() {
    if (!instance.state.smallColumns) {
      instance.setState({xSmallColumns: 12, smallColumns: 6});
    }
    await this.refresh();
  }

  async componentWillUnmount() {

  }

  render() {
    const formId = 'nope';

    function RenderSelectedFormValues() {
      const {formId, subordinateId, submissionDate} = useParams();

      const query = new URLSearchParams(useLocation().search);
      const title = query.get('title');

      instance.state.currentFormId = formId;
      instance.state.currentSubordinateId = subordinateId;
      instance.state.currentSubmissionDate = submissionDate;

      const formValues = instance.state.formValues;
      const byUserName = formValues && formValues._byUserName ? ' - by ' + formValues._byUserName : '';
      const formValuesArray = instance.state.formValuesArray;

      const formTitle = title && formValues ? `${formValues.createTimestamp} - ${title}${byUserName}` : formValues ? formValues.createTimestamp+byUserName : title ? title + byUserName : '';

      return formValuesArray && formValuesArray.length > 0 ? (
        <div>
          <style>
            .MultiListItem-gutters {
              'border-top: 1px solid #ddd;'
            }
            .ListItemKey {
              'padding-right: 60px;'
            }
            .listItemValue {
              'text-align: right;' +
               'font-weight: 700;'
            }

            .formItemResponse {
              'display: flexbox;' +
              'flex-direction: row;' +
              'flex-wrap: nowrap;' +
              'justify-content: flex-start;'+
              'align-items: flex-start;'
            }

            .formItemResponse .value {
              'flex-basis: 50px;'
            }

            .formItemResponse .question {
              'flex-grow: 1'
            }
          </style>

          <style>
            {`
            .formItemResponse {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: flex-start;              
              align-items: flex-start;
              height: 100%;
            }

            .MuiGrid-item{
              border: 1px solid #ccc;
              page-break-inside: avoid;
            }

            .formItemResponse .value {
              border-right: 1px solid #ccc;
              padding-right: 3px;
              padding-left: 3px;
              min-width: 5em;
              min-height: 100%;
            }

            .formItemResponse .value.isBoolean {
              min-width: 2em;
            }

            .formItemResponse .value .checkMark {
              margin-top: -0.2em;
              color: green;
            }

            .formItemResponse .question {
              flex-grow: 1;
              padding-left: 3px;             
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .formItemResponse .question.condensed{
              white-space: nowrap;
            }

            @page:left{
              @bottom-left {
                content: "Page " counter(page) " of " counter(pages);
              }
            }
            `}
          </style>
          <div className="ButtonGroupTitle">{formTitle}</div>
          <Grid container spacing={0} columns={12}>
            {formValuesArray.map((x)=> !x.itemName.startsWith('_') && !HIDDEN_FORM_FIELDS.includes(x.itemName) ? (
                <Grid item key={x.itemName} xs={instance.state.xSmallColumns} sm={instance.state.smallColumns} md={4} lg={3} xl={2}>
                  <div className={'formItemResponse'}>
                    { !x.areNotes ? (
                    <div className={'value '+ (x.isCheckmark || !x.value ? 'isBoolean' : '')}>
                      {x.isCheckmark ?
                      ( <IconCheck className={'checkMark'} style={{'margin-right': '5px', 'vertical-align': 'middle'}}></IconCheck> ) : x.value}</div>
                      ) : ''}
                    <div className={'question ' + (!x.areNotes ? 'condensed' : '')}>{x.itemName + (x.areNotes ? ':' : '')}
                      { x.areNotes ? (
                        <div style={{'margin-left': '2em'}}>{x.value}</div>
                      ) : ''}
                    </div>
                  </div>
                </Grid>
            ) : '')}
          </Grid>
        </div>
        ) : (
        <div>Loading form values...</div>
      );
    }

    // const { formId } = useParams();
    const form = null;

    // form = instance.state.resources.forms.filter(x=>x.id === id)[0];

    return (
      <div>
        {this.state.redirectPath ? (
            <Redirect push to={this.state.redirectPath} />
          ) : ''}
        <Button onClick={() => instance.handleClick('back')}>&lt;&nbsp;Back</Button>

        {instance.props.user ? (
            <RenderSelectedFormValues />
          ) : (
            <div>Loading route...</div>
            )
        }

      </div>
    );
  }
}

export default UserForm;
