import React, {Component} from 'react';
import axios from 'axios';

import {List, ListItem, ListItemText, ListSubheader, Divider, Grid} from '@material-ui/core';

// v4 icons found here: https://v4.mui.com/components/material-icons/
import {GroupOutlined as IconGroup} from '@material-ui/icons';
import {AssignmentOutlined as NoteIcon} from '@material-ui/icons';
import {Check as IconCheck} from '@material-ui/icons';


import {
  Redirect,
} from 'react-router-dom';

import {Button, ButtonGroup, Container} from '@material-ui/core';
import {stringify} from 'querystring';
import CircularProgressWithLabel from '../../UX/CircularProgressWithLabel';

const HIDDEN_FORM_FIELDS = ['Form Response Edit URL', 'UUID', 'TGUUID', 'INIT', 'createTimestampMillis', 'createTimestamp', 'Timestamp', 'isShared'];

const API_PROTOCOL=process.env.REACT_APP_API_PROTOCOL;// 'https://';
const API_HOST=process.env.REACT_APP_API_HOST;// 'api.advancepsych.schedulekeep.com';

const REFRESH_INTERVALL_MILLIS = 1000 * 30;

let instance = null;
class RecentForms extends React.Component {
  constructor() {
    super();

    this.state = {
      recentForms: null,
    };
    instance = this;
  }

  async callAPIAsync(path) {
    if (!instance.props.authUser) {
      return null;
    }
    try {
      const token = instance.props.authUser.getAuthResponse().id_token;
      const response = await axios.get(`${API_PROTOCOL}://${API_HOST}/${path}`, {
        headers: {
          'x-user-token': token,
        },
      });

      return response;
    } catch (err) {
      console.log('callAPIAsync: unable to complete call: '+err);
    }
  }

  async refreshRecentForms() {
    instance.setState({isLoading: true});
    let maxCount = 150;
    debugger;
    let path = 'reports/recentforms';
    if (instance.props.isIncludeAllFormValues) {
      path = path + '/allvalues?maxCount='+maxCount;
      maxCount = 20;
    } else {
      path = path + '?maxCount='+maxCount;
    }
    const response = await this.callAPIAsync(path);
    if (response && response.data && response.data.formResponses) {
      const formResponses = response.data.formResponses.filter((x, i) => i < 50);// .sort((a, b) => a.createTimestampMillis < b.createTimestampMillis);
      const formsByDate = new Map();
      formResponses.forEach((x)=>{
        x._date = x.timestamp.split(' ')[0];
        if (!formsByDate.get(x._date)) {
          formsByDate.set(x._date, []);
        }
        formsByDate.get(x._date).push(x);

        if (x.allFormValues) {
          x.formValuesArray = [];
          const allFormValuesKeys = Object.keys(x.allFormValues);
          allFormValuesKeys.forEach((key) => {
            const value = x.allFormValues[key];
            const v = (value+'').toLowerCase().trim();
            const isCheckmark = v.startsWith('complete') || v == 'yes' || v == 'done';
            // console.log('question: '+key+' :: v: '+v+'. isCheckmark: '+isCheckmark);

            const q = (key+'').toLowerCase().trim();
            const areNotes = q.startsWith('notes');
            x.formValuesArray.push({itemName: key, value, isCheckmark, areNotes});
          });
        }
      });

      instance.setState({recentForms: formsByDate});
    } else {
      instance.setState({recentForms: new Map()});
    }

    instance.setState({isLoading: false});
  }

  handleClick(e) {
    console.log('navigating to '+e);
    if (e && e.includes('http')) {
      window.open(e, '_blank');
    } else if (e === 'back') {
      window.history.back();
    } else if (e) {
      this.setState({redirectPath: e});
    }
  }

  async refresh() {
    await this.refreshRecentForms();
  }

  async componentDidMount() {
    await this.refresh();
  }

  async componentWillUnmount() {

  }

  render() {
    function RenderFormLinks() {
      const recentForms = instance.state.recentForms ? instance.state.recentForms : [];
      const dates = Array.from( recentForms.keys() );

      return recentForms ? (
        <div>

          <List subheader={<li />}>
            {dates.map((date) => (
              <li key={`date`}>
                <ul style={{'padding-left': '0px'}}>
                  <Divider></Divider>
                  <ListSubheader>{`${date}`}</ListSubheader>
                  {recentForms.get(date).map((item) => {
                    const targetUser = item.targetUser ? `${item.targetUser.firstName} ${item.targetUser.lastName}` : '';
                    const targetUserTitle = targetUser ? ` - regarding ${targetUser}` : '';
                    const descriptionValue = item.descriptionValue ? ` (${item.descriptionValue})` : '';
                    const lastEditedBy = item.lastEditedBy ? `${item.lastEditedBy.firstName} ${item.lastEditedBy.lastName}` : '';
                    const title = `${item.formName} - ${lastEditedBy}`;
                    const titleForLink = `${item.formName} - ${targetUser ? targetUser : lastEditedBy}`;
                    const subordinateId = item.targetUser ? item.targetUser.id : item.lastEditedBy.id;
                    return (
                      <div key={`${item.createTimestampMillis}`}>
                        <ListItem button onClick={() => instance.handleClick(`/subordinates/${subordinateId}/forms/${item.formId}/submission/${item.createTimestampMillis}?title=${encodeURI(titleForLink)}`)}>
                          <CircularProgressWithLabel style={{'margin-right': '5px'}} value={item.percentComplete} />
                          {item.isShared ? (
                          <IconGroup style={{'margin-right': '5px', 'vertical-align': 'middle'}}></IconGroup>
                        ) : ''}

                          {item.hasNotes ? (
                          <NoteIcon style={{'margin-right': '5px', 'vertical-align': 'middle'}}></NoteIcon>
                        ) : ''}

                          <ListItemText primary={title} secondary={`${item.timestamp.split(' ')[1].trim()}${targetUserTitle}${descriptionValue}`}/>

                        </ListItem>

                        {Array.isArray(item.formValuesArray) ? (


                        <div>
                          <style>
                            .MultiListItem-gutters {
                              'border-top: 1px solid #ddd;'
                            }
                            .ListItemKey {
                              'padding-right: 60px;'
                            }
                            .listItemValue {
                              'text-align: right;' +
                              'font-weight: 700;'
                            }

                            .formItemResponse {
                              'display: flexbox;' +
                              'flex-direction: row;' +
                              'flex-wrap: nowrap;' +
                              'justify-content: flex-start;'+
                              'align-items: flex-start;'
                            }

                            .formItemResponse .value {
                              'flex-basis: 50px;'
                            }

                            .formItemResponse .question {
                              'flex-grow: 1'
                            }
                          </style>

                          <style>
                            {`
                            .formItemResponse {
                              display: flex;
                              flex-direction: row;
                              flex-wrap: nowrap;
                              justify-content: flex-start;              
                              align-items: flex-start;
                              height: 100%;
                            }

                            .MuiGrid-item{
                              border: 1px solid #ccc;
                              page-break-inside: avoid;
                            }

                            .formItemResponse .value {
                              border-right: 1px solid #ccc;
                              padding-right: 3px;
                              padding-left: 3px;
                              min-width: 5em;
                              min-height: 100%;
                            }

                            .formItemResponse .value.isBoolean {
                              min-width: 2em;
                            }

                            .formItemResponse .value .checkMark {
                              margin-top: -0.2em;
                              color: green;
                            }

                            .formItemResponse .question {
                              flex-grow: 1;
                              padding-left: 3px;             
                              overflow: hidden;
                              text-overflow: ellipsis;
                            }

                            .formItemResponse .question.condensed{
                              white-space: nowrap;
                            }

                            @page:left{
                              @bottom-left {
                                content: "Page " counter(page) " of " counter(pages);
                              }
                            }
                            `}
                          </style>
                          <Grid container spacing={0} columns={12}>
                            {item.formValuesArray.map((x)=> !x.itemName.startsWith('_') && !HIDDEN_FORM_FIELDS.includes(x.itemName) ? (
                              <Grid item key={x.itemName} xs={instance.state.xSmallColumns} sm={instance.state.smallColumns} md={4} lg={3} xl={2}>
                                <div className={'formItemResponse'}>
                                  { !x.areNotes ? (
                                  <div className={'value '+ (x.isCheckmark || !x.value ? 'isBoolean' : '')}>
                                    {x.isCheckmark ?
                                    ( <IconCheck className={'checkMark'} style={{'margin-right': '5px', 'vertical-align': 'middle'}}></IconCheck> ) : x.value}</div>
                                    ) : ''}
                                  <div className={'question ' + (!x.areNotes ? 'condensed' : '')}>{x.itemName + (x.areNotes ? ':' : '')}
                                    { x.areNotes ? (
                                      <div style={{'margin-left': '2em'}}>{x.value}</div>
                                    ) : ''}
                                  </div>
                                </div>
                              </Grid>
                          ) : '')}
                          </Grid>
                        </div>
                      ) : ''}

                      </div>
                    );
                  })}
                </ul>
              </li>
            ))}
          </List>


          {/* <div>
            <div className="ButtonGroupTitle">Recent Forms</div>

            <div>
              <ButtonGroup className={'listItems'} orientation="vertical" color="primary" aria-label="vertical contained primary button group" variant="text">
                {instance.state.recentForms.map((x)=>(
                  <Button key={x.createTimestamp} onClick={() => instance.handleClick(`${formId}/submission/${x.createTimestampMillis}`)}><CircularProgressWithLabel style={{'margin-right': '5px'}} value={x.percentComplete} />
                    {x.createTimestamp}
                    {x.isShared && x.lastEditedBy ? ` - ${x.lastEditedBy.firstName} ${x.lastEditedBy.lastName}` : ''}
                    {x.targetUser ? ` - ${x.targetUser.firstName} ${x.targetUser.lastName}` : ''}
                  </Button>
                ))}
              </ButtonGroup>
            </div>


          </div> */}
        </div>
        ) : (
        <div>No recent forms</div>
      );
    }

    // const { formId } = useParams();
    const form = null;

    // form = instance.state.resources.forms.filter(x=>x.id === id)[0];

    return (
      <div>
        {this.state.redirectPath ? (
            <Redirect push to={this.state.redirectPath} />
          ) : ''}
        <Button onClick={() => instance.handleClick('back')}>&lt;&nbsp;Back</Button>

        {!this.state.isLoading ? (
            <RenderFormLinks />
          ) : (
            <div>Loading Recent Forms...</div>
            )
        }

      </div>
    );
  }
}

export default RecentForms;
