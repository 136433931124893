import React, {Component} from 'react';
import axios from 'axios';

import {
  Switch,
  Route,
  Link,
  useParams,
  Redirect,
} from 'react-router-dom';

import {Button, ButtonGroup, Container} from '@material-ui/core';
import {stringify} from 'querystring';

const API_PROTOCOL=process.env.REACT_APP_API_PROTOCOL;
const API_HOST=process.env.REACT_APP_API_HOST;

let instance = null;
class Subordinate extends React.Component {
  constructor() {
    super();

    this.state = {
      subordinateForms: null,
    };
    instance = this;
  }

  async callAPIAsync(path) {
    if (!instance.props.authUser) {
      // this.setState({redirectPath: '/signin'});
      return null;
    }
    try {
      const token = instance.props.authUser.getAuthResponse().id_token;
      const response = await axios.get(`${API_PROTOCOL}://${API_HOST}/${path}`, {
        headers: {
          'x-user-token': token,
        },
      });

      return response;
    } catch (err) {
      console.log('callAPIAsync: unable to complete call: '+err);
    }
  }

  // async refreshSubordinateForms() {
  //   const subordinateId = instance.state.currentSubordinateId;
  //   if (subordinateId && subordinateId !== this.state.lastFormId) {
  //     instance.state.lastFormId = subordinateId;
  //     const response = await this.callAPIAsync('users/subordinateForms/'+subordinateId);
  //     if (response && response.data) {
  //       // console.log(`Resources returned from API: ${JSON.stringify(response.data)}`);
  //       instance.setState({subordinateForms: response.data.forms, _report: response.data._report});
  //     } else {
  //       instance.setState({subordinateForms: [], _report: null});
  //     }
  //   }
  // }

  handleClick(e) {
    // e.preventDefault();
    // console.log('The link was clicked: '+JSON.stringify(e));

    if (e && e.includes('http')) {
      window.open(e, '_blank');
    } else if (e) {
      this.setState({redirectPath: e});
    }
  }

  // async refresh() {
  //   await this.refreshSubordinateForms();
  // }

  async componentDidMount() {
    // No need to refresh, since the data for this component is provided by props.
    // await this.refresh();
  }

  async componentWillUnmount() {
  }

  render() {
    function RenderSelectedSubordinateFormLinks() {
      const {subordinateId} = useParams();

      // console.log('subordinateId from params: '+subordinateId);
      instance.state.currentSubordinateId = subordinateId;
      let selectedSubordinate = null;


      if (instance.props.resources && instance.props.resources.userGroups) {
        const userGroups = instance.props.resources.userGroups;
        for (let i=0; i<userGroups.length && !selectedSubordinate; i++) {
          const userGroup = userGroups[i];
          selectedSubordinate = userGroup.userReports.filter((x)=>x._userId === subordinateId);
          if (Array.isArray(selectedSubordinate) && selectedSubordinate.length > 0) {
            selectedSubordinate = selectedSubordinate[0];
          }

          instance.state.currentSubordinateId = selectedSubordinate._id;
        }
      }

      let subordinateForms;
      let letterGrade;
      let grade;

      if (selectedSubordinate) {
        subordinateForms = selectedSubordinate.formReports ? selectedSubordinate.formReports : [];
        letterGrade = selectedSubordinate._report ? '('+selectedSubordinate._report.letterGrade+')' : null;
        grade = selectedSubordinate._report ? ' - ' + (Math.round(selectedSubordinate._report.grade * 10) / 10) + '%' : null;
      }

      return selectedSubordinate ? (
        <div>
          <div className="ButtonGroupTitle">{selectedSubordinate.firstName + ' ' + selectedSubordinate.lastName} - Forms {grade} {letterGrade}</div>
          { subordinateForms ? (
            <div>
              <ButtonGroup className={'listItems'} orientation="vertical" color="primary" aria-label="vertical contained primary button group" variant="text">
                {subordinateForms.map((x)=>{
                  const formLetterGrade = x._report ? '('+x._report.letterGrade+') - '+x._report.count : null;
                  const formGrade = x._report ? ' - ' + (Math.round(x._report.grade * 10) / 10) + '%' : null;
                  return (
                    <Button key={x._formId} onClick={() => instance.handleClick(`${subordinateId}/forms/${x._formId}`)}>{x._formName} {formGrade} {formLetterGrade}</Button>
                  );
                })}
              </ButtonGroup>
            </div>
          ) : (
            <div>Loading subordinate forms...</div>
          )}

        </div>
        ) : (
        <div>No subordinate user selected.</div>
      );
    }

    // const { formId } = useParams();
    const form = null;

    // form = instance.state.resources.forms.filter(x=>x.id === id)[0];

    return (
      <div>
        {this.state.redirectPath ? (
            <Redirect push to={this.state.redirectPath} />
          ) : ''}
        <Button onClick={() => instance.handleClick(`/`)}>&lt;&nbsp;Back</Button>

        {instance.props.user ? (
            <RenderSelectedSubordinateFormLinks />
          ) : (
            <div>Loading route...</div>
            )
        }

      </div>
    );
  }
}

export default Subordinate;
