import React, {Component} from 'react';
import axios from 'axios';

import {
  Switch,
  Route,
  Link,
  useParams,
  Redirect,
} from 'react-router-dom';

import {Button, ButtonGroup, Container} from '@material-ui/core';
import {stringify} from 'querystring';
import CircularProgressWithLabel from '../UX/CircularProgressWithLabel';
import {isArray} from 'util';

const API_PROTOCOL=process.env.REACT_APP_API_PROTOCOL;// 'https://';
const API_HOST=process.env.REACT_APP_API_HOST;// 'api.advancepsych.schedulekeep.com';

const REFRESH_INTERVALL_MILLIS = 1000 * 30;

let instance = null;
class UserForm extends React.Component {
  constructor() {
    super();

    this.state = {
      userForms: null,
    };
    instance = this;
  }

  async callAPIAsync(path) {
    if (!instance.props.authUser) {
      return null;
    }
    try {
      const token = instance.props.authUser.getAuthResponse().id_token;
      const response = await axios.get(`${API_PROTOCOL}://${API_HOST}/${path}`, {
        headers: {
          'x-user-token': token,
        },
      });

      return response;
    } catch (err) {
      console.log('callAPIAsync: unable to complete call: '+err);
    }
  }

  async refreshSubordinateForms() {
    const formId = instance.state.currentFormId;
    const subordinateId = instance.state.currentSubordinateId;
    if (formId && formId !== this.state.lastFormId && subordinateId && subordinateId != this.state.lastSubordinateId) {
      instance.state.lastFormId = formId;
      instance.state.lastSubordinateId = subordinateId;
      const response = await this.callAPIAsync(`users/subordinateForms/${subordinateId}/forms/${formId}`);
      if (response && response.data && Array.isArray(response.data.formResponses)) {
        const formattedData = response.data.formResponses.map((x)=>{
          return {...x,
            createTimestamp: `${x.createTimestampMillis ? new Date(parseInt(x.createTimestampMillis)).toLocaleString() : new Date(x.timestamp).toLocaleString()}`,
          };
        });
        // console.log(`Resources returned from API: ${JSON.stringify(response.data)}`);
        instance.setState({userForms: formattedData, _report: response.data._report});
      } else {
        instance.setState({userForms: []});
      }
    }
  }

  handleClick(e) {
    console.log('navigating to '+e);
    if (e && e.includes('http')) {
      window.open(e, '_blank');
    } else if (e === 'back') {
      window.history.back();
    } else if (e) {
      this.setState({redirectPath: e});
    }
  }

  async refresh() {
    await this.refreshSubordinateForms();
  }

  async componentDidMount() {
    await this.refresh();
  }

  async componentWillUnmount() {

  }

  render() {
    const formId = 'nope';

    function RenderSelectedFormLinks() {
      const {formId, subordinateId} = useParams();
      instance.state.currentSubordinateId = subordinateId;
      instance.state.currentFormId = formId;
      console.log('formId: '+formId+'. subordinateId: '+subordinateId);

      let formReport = null;
      let userReport = null;

      if (instance.props.resources && instance.props.resources.userGroups && Array.isArray(instance.props.resources.userGroups)) {
        const userGroups = instance.props.resources.userGroups;
        let userGroup = userGroups.filter((x)=> Array.isArray(x.userIds) && x.userIds.includes(subordinateId));
        if (Array.isArray(userGroup)) {
          userGroup = userGroup[0];
        }
        if (Array.isArray(userGroup.userReports)) {
          userReport = userGroup.userReports.filter((x)=> x._userId == subordinateId);
          if (Array.isArray(userReport)) {
            userReport = userReport[0];
          }
          if (userReport && Array.isArray(userReport.formReports)) {
            const formReports = userReport.formReports;
            let formReportResult = formReports.filter((x)=> x._formId == formId);
            if (Array.isArray(formReportResult)) {
              formReportResult = formReportResult[0];
            }
            if (formReportResult) {
              formReport = formReportResult;
            }
          }
        }
      }


      let letterGrade = null;
      let grade = null;

      if (formReport && formReport._report) {
        letterGrade = formReport._report ? '('+formReport._report.letterGrade+')' : null;
        grade = formReport._report ? ' - ' + (Math.round(formReport._report.grade * 10) / 10) + '%' : null;
      }

      return userReport && formReport ? (
        <div>
          <div className="ButtonGroupTitle">{userReport.firstName} {userReport.lastName} - {formReport._formName} {grade} {letterGrade}</div>
          {instance.state.userForms ? (
            <div>
              <ButtonGroup className={'listItems'} orientation="vertical" color="primary" aria-label="vertical contained primary button group" variant="text">
                {instance.state.userForms.map((x)=>(
                  <Button key={x.createTimestamp} onClick={() => instance.handleClick(`${formId}/submission/${x.createTimestampMillis}`)}><CircularProgressWithLabel style={{'margin-right': '5px'}} value={x.percentComplete} />
                    {x.createTimestamp}
                    {x.isShared && x.lastEditedBy ? ` - ${x.lastEditedBy.firstName} ${x.lastEditedBy.lastName}` : ''}
                    {/* {x.targetUser ? ` - ${x.targetUser.firstName} ${x.targetUser.lastName}` : ''} */}
                    {/* {x.targetUser && x.byUser ? ` - By ${x.byUser.firstName} ${x.byUser.lastName}` : ''} */}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
          ) : (
            <div>Loading forms...</div>
          )}

        </div>
        ) : (
        <div>No form selected</div>
      );
    }

    // const { formId } = useParams();
    const form = null;

    // form = instance.state.resources.forms.filter(x=>x.id === id)[0];

    return (
      <div>
        {this.state.redirectPath ? (
            <Redirect push to={this.state.redirectPath} />
          ) : ''}
        <Button onClick={() => instance.handleClick('back')}>&lt;&nbsp;Back</Button>

        {instance.props.user ? (
            <RenderSelectedFormLinks />
          ) : (
            <div>Loading route...</div>
            )
        }

      </div>
    );
  }
}

export default UserForm;
