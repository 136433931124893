import React, {Component} from 'react';
import axios from 'axios';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import {Group as IconGroup} from '@material-ui/icons';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import {
  Redirect,
} from 'react-router-dom';

import {Button, ButtonGroup, Container} from '@material-ui/core';
import {stringify} from 'querystring';
import CircularProgressWithLabel from '../../UX/CircularProgressWithLabel';

const API_PROTOCOL=process.env.REACT_APP_API_PROTOCOL;// 'https://';
const API_HOST=process.env.REACT_APP_API_HOST;// 'api.advancepsych.schedulekeep.com';

const REFRESH_INTERVALL_MILLIS = 1000 * 30;

let instance = null;
class RecentFormsGrid extends React.Component {
  constructor() {
    super();

    this.state = {
      allFormsObj: null,
      allUsersArray: null,
    };
    instance = this;
  }

  async callAPIAsync(path) {
    if (!instance.props.authUser) {
      return null;
    }
    try {
      const token = instance.props.authUser.getAuthResponse().id_token;
      const response = await axios.get(`${API_PROTOCOL}://${API_HOST}/${path}`, {
        headers: {
          'x-user-token': token,
        },
      });

      return response;
    } catch (err) {
      console.log('callAPIAsync: unable to complete call: '+err);
    }
  }

  refreshFormGridData() {
    // instance.props.resources && instance.props.resources.userGroups && Array.isArray(instance.props.resources.userGroups
    if (instance.props.resources && Array.isArray(instance.props.resources.userGroups)) {
      const allFormsObj = {};
      const allUsersArray = [];

      instance.props.resources.userGroups.forEach((userGroup) => {
        if (Array.isArray(userGroup.userReports)) {
          userGroup.userReports.forEach((userReport) => {
            allUsersArray.push(userReport);

            if (!userReport.formsIndexed) {
              userReport.formsIndexed = {};
            }

            if (Array.isArray(userReport.formReports)) {
              userReport.formReports.forEach((formReport) => {
                userReport.formsIndexed[formReport._formId] = formReport;
                allFormsObj[formReport._formId] = formReport;
              });
            }
          });
        }
      });

      instance.setState({
        allFormsObj,
        allUsersArray,
      });
    }
  }


  handleClick(e, event) {
    if (event) {
      event.preventDefault();
    }
    console.log('navigating to '+e);
    if (e && e.includes('http')) {
      window.open(e, '_blank');
    } else if (e === 'back') {
      window.history.back();
    } else if (e) {
      this.setState({redirectPath: e});
    }
  }

  async refresh() {
    await this.refreshFormGridData();
  }

  async componentDidMount() {
    await this.refresh();
  }

  async componentWillUnmount() {

  }

  render() {
    function RenderFormLinks() {
      const allFormsObj = instance.state.allFormsObj ? instance.state.allFormsObj : null;
      const allUsersArray = instance.state.allUsersArray ? instance.state.allUsersArray : null;
      // Each user also has a new 'formsIndexed' object that needs to be used.

      return allUsersArray && allFormsObj ? (
        <div>


          <Paper>
            <TableContainer style={{'max-height': 'calc(100vh - 100px)'}}>
              <Table class="stickycolumn stickyheader">
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    {
                      Object.keys(allFormsObj).map((formKey) => {
                        return (
                          <TableCell key={formKey} style={{minWidth: '4.5em'}}>{allFormsObj[formKey]._formName}</TableCell>
                        );
                      })
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    allUsersArray.map((user) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={user._id}>
                          <TableCell>{user.firstName + ' ' + user.lastName}</TableCell>
                          {
                            Object.keys(allFormsObj).map((formKey) => {
                              return (
                                <TableCell key={user._id+'_'+formKey}>
                                  {
                                  user.formsIndexed[formKey] ?
                                  (
                                      <a href='#' onClick={(e) => instance.handleClick(`/subordinates/${user._userId}/forms/${formKey}`, e)}>
                                        {`${Math.round(user.formsIndexed[formKey]._report.grade*10)/10}% ${user.formsIndexed[formKey]._report.letterGrade} (${user.formsIndexed[formKey]._report.count})`}
                                      </a>
                                  ) : '-'
                                  }
                                </TableCell>
                              );
                            },
                            )
                          }
                        </TableRow>
                      );
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </div>
        ) : (
        <div>Loading Report...</div>
      );
    }

    const form = null;

    return (
      <div>
        {this.state.redirectPath ? (
            <Redirect push to={this.state.redirectPath} />
          ) : ''}
        <Button onClick={() => instance.handleClick('back')}>&lt;&nbsp;Back</Button>

        {instance.props.user ? (
            <RenderFormLinks />
          ) : (
            <div>Loading Recent Forms...</div>
            )
        }

      </div>
    );
  }
}

export default RecentFormsGrid;
