import React, {Component} from 'react';
import axios from 'axios';

import {
  Switch,
  Route,
  Link,
  useParams,
  Redirect,
} from 'react-router-dom';

import {Button, ButtonGroup, Dialog, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, Avatar} from '@material-ui/core';
import {Group as IconGroup} from '@material-ui/icons';
import {stringify} from 'querystring';
import CircularProgressWithLabel from '../UX/CircularProgressWithLabel';

const API_PROTOCOL=process.env.REACT_APP_API_PROTOCOL;// 'https://';
const API_HOST=process.env.REACT_APP_API_HOST;// 'api.advancepsych.schedulekeep.com';

const REFRESH_INTERVALL_MILLIS = 1000 * 30;

let instance = null;
let lastRefreshMillis = new Date().getTime();
let refreshInterval = null;
class UserForm extends React.Component {
  constructor() {
    super();

    this.state = {
      userForms: null,
      isModalOpen: false,
    };
    instance = this;
  }

  async callAPIAsync(path) {
    if (!instance.props.authUser) {
      // this.setState({redirectPath: '/signin'});
      return null;
    }
    try {
      const token = instance.props.authUser.getAuthResponse().id_token;
      const response = await axios.get(`${API_PROTOCOL}://${API_HOST}/${path}`, {
        headers: {
          'x-user-token': token,
        },
      });

      return response;
    } catch (err) {
      console.log('callAPIAsync: unable to complete call: '+err);
    }
  }

  async refreshUserForms() {
    const formId = instance.state.currentFormId;
    if (formId && formId !== this.state.lastFormId) {
      instance.state.lastFormId = formId;
      const response = await this.callAPIAsync('users/forms/'+formId);
      if (response && response.data && response.data) {
        const formattedData = response.data.map((x)=>{
          return {...x,
            createTimestamp: `${x.createTimestampMillis ? new Date(parseInt(x.createTimestampMillis)).toLocaleString() : new Date(x.timestamp).toLocaleString()}`,
          };
        });
        // console.log(`Resources returned from API.`);
        // console.log(`Resources returned from API: ${JSON.stringify(formattedData)}`);
        instance.setState({userForms: formattedData, _report: response.data._report});
      } else {
        instance.setState({userForms: []});
      }
    }
  }

  UserDialog(props) {
    const selectedValue = null;
    const {onClose, selectedForm, open} = props;

    const handleClose = () => {
      instance.state.isModalOpen = false;
      onClose(selectedValue);
    };

    const handleListItemClick = (value) => {
      window.setTimeout(()=>{
        instance.state.isModalOpen = false;
        onClose(value);
      }, 1000);
    };

    const userGroups = instance.state.userGroups ? instance.state.userGroups : [];

    return (
      <Dialog onClose={handleClose} aria-labelledby="user-dialog-title" open={instance.state.isModalOpen}>
        <DialogTitle id="user-dialog-title">Who is this about?</DialogTitle>
        <List>
          {userGroups.map((userGroup) =>
            (
            // Subordinate user groups
              <div key={userGroup.id}>
                <div style={{'background-color': '#eee', 'padding': '10px'}}><IconGroup style={{'vertical-align': 'middle', 'margin-right': '5px'}}></IconGroup>{userGroup.name}</div>

                {userGroup.userReports.map((user) =>
                  (
                    <ListItem button key={user._userId} style={{'padding-top': '20px', 'padding-bottom': '20px'}} onClick={() => props.handleClick(user._userId)}>
                      {user.firstName+' '+user.lastName}
                    </ListItem>
                  ),
                )}

              </div>
            ),
          )}

          {/* <ListItem autoFocus button onClick={() => handleListItemClick('addAccount')}>
            <ListItemAvatar>
              <Avatar>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Add account" />
          </ListItem> */}
        </List>
      </Dialog>
    );
  }

  handleClick(e) {
    if (e && e.includes('http')) {
      window.open(e, '_blank');
    } else if (e === 'back') {
      window.history.back();
    } else if (e) {
      this.setState({redirectPath: e});
    }
  }

  async refresh() {
    lastRefreshMillis = new Date().getTime();
    await this.refreshUserForms();
  }

  async componentDidMount() {
    await this.refresh();

    if (!refreshInterval) {
      refreshInterval = setInterval(async () => {
        if (document.hasFocus() && new Date().getTime() > lastRefreshMillis + REFRESH_INTERVALL_MILLIS) {
          console.log('Interval refresh.');
          instance.state.lastFormId = null;
          await instance.refresh();
        }
      }, 1000);
    }
  }

  async componentWillUnmount() {
    if (refreshInterval) {
      clearInterval(refreshInterval);
      refreshInterval = null;
      console.log('Clearing refresh interval.');
    }
  }

  render() {
    const formId = 'nope';
    let selectedForm = null;

    function openModal() {
      instance.setState({isModalOpen: true});
    }
    async function refreshModal() {
      const response = await instance.callAPIAsync('users/resources');
      if (response) {
        const responseData = response.data;
        instance.setState({resources: responseData});
        const userGroups = instance.state.resources && instance.state.resources.userGroups ? instance.state.resources.userGroups : [];
        instance.setState({userGroups: userGroups});
      }
    }
    function closeModal() {
      instance.setState({isModalOpen: false});
    }
    function openNewList() {
      if (!selectedForm || !selectedForm.targetUserIdFieldName) {
        instance.handleClick(`${selectedForm.uri}?usp=pp_url&${selectedForm.uuidFieldName}=${instance.props.user.id}&${selectedForm.createdTimeFieldName}=${new Date().getTime()}`);
      } else {
        refreshModal();
        openModal();
      }
    }
    function selectUserAndThenOpenNewList(targetUserId) {
      window.setTimeout(()=>{
        closeModal();
        instance.handleClick(`${selectedForm.uri}?usp=pp_url&${selectedForm.uuidFieldName}=${instance.props.user.id}&${selectedForm.createdTimeFieldName}=${new Date().getTime()}&${selectedForm.targetUserIdFieldName}=${targetUserId}`);
      }, 500);
    }

    function RenderSelectedFormLinks() {
      const {formId} = useParams();

      instance.state.currentFormId = formId;
      selectedForm = instance.props.resources && instance.props.resources.forms ? instance.props.resources.forms.filter((x)=>x.id === formId)[0] : null;
      const letterGrade = instance.state._report ? '('+instance.state._report.letterGrade+')' : null;
      const grade = instance.state._report ? instance.state._report.grade + '%' : null;
      return selectedForm ? (
        <div>
          <div className="ButtonGroupTitle">{selectedForm.isShared ? (
              <IconGroup style={{'margin-right': '5px', 'vertical-align': 'middle'}}></IconGroup>
            ) : ''}{selectedForm.name}</div>
          <ButtonGroup className={'listItems'} orientation="vertical" color="secondary" aria-label="vertical contained primary button group" variant="text">
            <Button onClick={openNewList}>Open New List</Button>
          </ButtonGroup>
          {instance.state.userForms ? (
            <div>
              <ButtonGroup className={'listItems'} orientation="vertical" color="primary" aria-label="vertical contained primary button group" variant="text">
                {instance.state.userForms.map((x)=>(
                  <Button key={x.createTimestamp} onClick={() => instance.handleClick(`${x.url + (x.isShared ? ('&'+selectedForm.uuidFieldName+'='+instance.props.user.id) : '')}`)}><CircularProgressWithLabel style={{'margin-right': '5px'}} value={x.percentComplete} />
                    <div style={{'text-align': 'left'}}>
                      {x.descriptionValue ? (
                        <div style={{'fontWeight': '700'}}>
                          {x.descriptionValue}
                        </div>
                      ) : ''}
                      {x.createTimestamp}
                      {x.isShared && x.lastEditedBy ? ` - ${x.lastEditedBy.firstName} ${x.lastEditedBy.lastName}` : ''}
                      {x.targetUser ? ` - ${x.targetUser.firstName} ${x.targetUser.lastName}` : ''}
                    </div>
                  </Button>
                ))
                }
              </ButtonGroup>
            </div>
          ) : (
            <div>Loading user forms...</div>
          )}

        </div>
        ) : (
        <div>No form selected</div>
      );
    }

    // const { formId } = useParams();
    const form = null;

    // form = instance.state.resources.forms.filter(x=>x.id === id)[0];

    return (
      <div>
        {this.state.redirectPath ? (
            <Redirect push to={this.state.redirectPath} />
          ) : ''}
        <Button onClick={() => instance.handleClick('back')}>&lt;&nbsp;Back</Button>

        {instance.props.user ? (
            <RenderSelectedFormLinks />
          ) : (
            <div>Loading route...</div>
            )
        }

        <this.UserDialog open={this.state.isModalOpen} selectedForm={selectedForm} handleClick={selectUserAndThenOpenNewList} onClose={closeModal} className="modalView" />
      </div>
    );
  }
}

export default UserForm;
