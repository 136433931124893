import React, {Component} from 'react';
import axios from 'axios';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import {Group as IconGroup} from '@material-ui/icons';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';

import {
  Redirect,
} from 'react-router-dom';

import {Button, ButtonGroup, Container} from '@material-ui/core';
import {FolderShared as IconFolderShared, ListAlt as IconListAlt, Person as IconPerson, TrendingUp, TrendingDown, Group, PieChart as IconPieChart} from '@material-ui/icons';
import {stringify} from 'querystring';
import CircularProgressWithLabel from '../../UX/CircularProgressWithLabel';

const API_PROTOCOL=process.env.REACT_APP_API_PROTOCOL;// 'https://';
const API_HOST=process.env.REACT_APP_API_HOST;// 'api.advancepsych.schedulekeep.com';


let instance = null;
class RecentFormsGrid extends React.Component {
  constructor() {
    super();

    this.state = {
      allFormsObj: null,
      allUsersArray: null,
    };
    instance = this;
  }

  async callAPIAsync(path) {
    if (!instance.props.authUser) {
      return null;
    }
    try {
      const token = instance.props.authUser.getAuthResponse().id_token;
      const response = await axios.get(`${API_PROTOCOL}://${API_HOST}/${path}`, {
        headers: {
          'x-user-token': token,
        },
      });

      return response;
    } catch (err) {
      console.log('callAPIAsync: unable to complete call: '+err);
    }
  }

  refreshTopBottomPerformingPersonnelByGroup() {
    const userGroupsAscending = [];
    const userGroupsDescending = [];
    if (instance.props.resources && Array.isArray(instance.props.resources.userGroups)) {
      instance.props.resources.userGroups.forEach((userGroup) => {
        if (Array.isArray(userGroup.userReports)) {
          const newAscendingUserGroup = {
            name: userGroup.name,
            userReports: [],
          };
          userGroup.userReports.forEach((x)=> x._report ? newAscendingUserGroup.userReports.push(x) : '');
          newAscendingUserGroup.userReports = newAscendingUserGroup.userReports.sort((a, b) => a._report.grade - b._report.grade);
          userGroupsAscending.push(newAscendingUserGroup);

          const newDescendingUserGroup = {
            name: userGroup.name,
            userReports: [],
          };
          userGroup.userReports.forEach((x)=> x._report ? newDescendingUserGroup.userReports.push(x) : '');
          newDescendingUserGroup.userReports = newDescendingUserGroup.userReports.sort((a, b) => b._report.grade - a._report.grade);
          userGroupsDescending.push(newDescendingUserGroup);
        }
      });
    }

    instance.setState({
      userGroupsAscending,
      userGroupsDescending,
    });
  }

  async refreshLowestFormFields() {
    const response = await this.callAPIAsync(`reports/getLowestFormFieldsForOrganization`);
    if (response && response.data) {
      instance.setState({lowestFormFields: response.data});
    } else {
      instance.setState({lowestFormFields: []});
    }
  }


  handleClick(e, event) {
    if (event) {
      event.preventDefault();
    }
    console.log('navigating to '+e);
    if (e && e.includes('http')) {
      window.open(e, '_blank');
    } else if (e === 'back') {
      window.history.back();
    } else if (e) {
      this.setState({redirectPath: e});
    }
  }

  async refresh() {
    await this.refreshTopBottomPerformingPersonnelByGroup();
    await this.refreshLowestFormFields();
  }

  async componentDidMount() {
    await this.refresh();
  }

  async componentWillUnmount() {

  }

  render() {
    function RenderFormLinks() {
      const userGroupsAscending = instance.state.userGroupsAscending ? instance.state.userGroupsAscending : null;
      const userGroupsDescending = instance.state.userGroupsDescending ? instance.state.userGroupsDescending : null;
      const lowestFormFields = instance.state.lowestFormFields ? instance.state.lowestFormFields : null;
      // Each user also has a new 'formsIndexed' object that needs to be used.

      return userGroupsAscending && userGroupsDescending && lowestFormFields ? (
          <div className="reports">
            <style> {'\
              .reportTitle {\
              background-color: white;\
              text-align: center;\
              padding-top: 40px;\
            }\
            '}</style>
            <div className={['ButtonGroupTitle', 'reportTitle'].join(' ')}><TrendingUp style={{'vertical-align': 'middle', 'margin-right': '5px'}}></TrendingUp>Top Performing Personnel by Group</div>
            <div className="reportsList">
              <img src="/graph1.png" style={{'width': '100%', 'max-width': '500px'}}></img>
              <img src="/graph2.png" style={{'width': '100%', 'max-width': '500px'}}></img>
              {userGroupsDescending.map((userGroup) => {
                return (
                  <div key={'descending'+userGroup.name}>
                    <div className="ButtonGroupTitle"><Group style={{'vertical-align': 'middle', 'margin-right': '5px'}}></Group>{userGroup.name}</div>

                    <ButtonGroup className={'listItems'} orientation="vertical" color="primary" aria-label="vertical contained primary button group" variant="text">

                      {
                        userGroup.userReports.map((report, index) => {
                          return index < 5 ? (
                            <Button key={'descending'+report._userId} onClick={() => instance.handleClick(`/subordinates/${report._userId}`)}>{`${report.firstName} ${report.lastName} - ${report._report.letterGrade} (${Math.round(report._report.grade)}%)`}</Button>
                          ) : '';
                        })
                      }
                    </ButtonGroup>


                  </div>
                );
              },
              )}
            </div>

            <div className="ButtonGroupTitle reportTitle"><TrendingDown style={{'vertical-align': 'middle', 'margin-right': '5px'}}></TrendingDown>Lowest Performing Personnel by Group</div>
            <div className="reportsList">
              <img src="/graph3.png" style={{'width': '100%', 'max-width': '500px'}}></img>
              {userGroupsAscending.map((userGroup) => {
                return (
                  <div key={'ascending'+userGroup.name}>
                    <div className="ButtonGroupTitle"><Group style={{'vertical-align': 'middle', 'margin-right': '5px'}}></Group>{userGroup.name}</div>

                    <ButtonGroup className={'listItems'} orientation="vertical" color="secondary" aria-label="vertical contained primary button group" variant="text">

                      {
                        userGroup.userReports.map((report, index) => {
                          return index < 5 ? (
                            <Button key={'ascending'+report._userId} onClick={() => instance.handleClick(`/subordinates/${report._userId}`)}>{`${report.firstName} ${report.lastName} - ${report._report.letterGrade} (${Math.round(report._report.grade)}%)`}</Button>
                          ) : '';
                        })
                      }
                    </ButtonGroup>


                  </div>
                );
              })}
              <img src="/graph4.png" style={{'width': '100%', 'max-width': '500px'}}></img>
              {
                <div>
                  <div className="ButtonGroupTitle"><Group style={{'vertical-align': 'middle', 'margin-right': '5px'}}></Group>Lowest Performing Form Fields - Last 14 Days</div>

                  <ButtonGroup className={'listItems'} orientation="vertical" color="primary" aria-label="vertical contained primary button group" variant="text">
                    {
                      lowestFormFields.map((formField, index) => {
                        return index < 200 ? (
                      <ListItem button key={'ascending'+formField.formDefinitionId+formField.fieldName}>
                        <ListItemText primary={`${formField.formDefinitionName}`} />
                        <ListItemText primary={`${formField.fieldName}`} />
                        <ListItemText secondary={`Grade: ${formField.fieldAverageGradeLetter} (${Math.round(formField.fieldAverageGrade)}%), Submissions: ${formField.fieldValueCount}, Different Users: ${formField.userIdCount}`} />
                      </ListItem>

                    ) : '';
                      })
                    }
                  </ButtonGroup>

                </div>
              }
            </div>
          </div>
        ) : (
        <div>Loading Report...</div>
      );
    }

    return (
      <div>
        {this.state.redirectPath ? (
            <Redirect push to={this.state.redirectPath} />
          ) : ''}
        <Button onClick={() => instance.handleClick('back')}>&lt;&nbsp;Back</Button>

        {instance.props.user ? (
            <RenderFormLinks />
          ) : (
            <div>Loading Recent Forms...</div>
            )
        }

      </div>
    );
  }
}

export default RecentFormsGrid;
