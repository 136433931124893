import React, {Component} from 'react';
import axios from 'axios';

import {
  Switch,
  Route,
  Link,
  useParams,
  Redirect,
} from 'react-router-dom';


import {Button, ButtonGroup, Avatar, Container, AppBar, Toolbar, IconButton, Typography} from '@material-ui/core';
import {Group as IconGroup, FolderShared as IconFolderShared, ListAlt as IconListAlt, Person as IconPerson, PieChart as IconPieChart} from '@material-ui/icons';
import {stringify} from 'querystring';
import MenuBar from './Components/MenuBar/MenuBar';

import UserForm from './Components/UserForm/UserForm';
import Signin from './Components/Signin/Signin';
import Subordinate from './Components/Subordinate/Subordinate';
import SubordinateForm from './Components/SubordinateForm/SubordinateForm';
import SubordinateFormValue from './Components/SubordinateFormValue/SubordinateFormValue';
import RecentForms from './Components/Reports/RecentForms/RecentForms';
import RecentFormsGrid from './Components/Reports/RecentFormsGrid/RecentFormsGrid';
import Dashboard from './Components/Reports/Dashboard/Dashboard';


const API_PROTOCOL=process.env.REACT_APP_API_PROTOCOL;// 'https://';
const API_HOST=process.env.REACT_APP_API_HOST;// 'api.advancepsych.schedulekeep.com';

let instance = null;
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiResponse: 'nothing of interest',
      user: {},
      authUser: null};
    instance = this;
  }

  async callAPIAsync(path) {
    this.setState({lastError: null});
    this.setState({lastErrorCode: null});

    if (!this.state.authUser) {
      this.setState({redirectPath: '/signin'});
      return null;
    }
    try {
      const token = this.state.authUser.getAuthResponse().id_token;
      const response = await axios.get(`${API_PROTOCOL}://${API_HOST}/${path}`, {
        headers: {
          'x-user-token': token,
        },
      });

      return response;
    } catch (err) {
      console.log('callAPIAsync: unable to complete call: '+err);
      throw err;
    }
  }

  async refreshUser() {
    const response = await this.callAPIAsync('users');
    if (response) {
      this.setState({user: response.data});
    }
  }

  async refreshOrganization() {
    const response = await this.callAPIAsync('organizations');
    if (response) {
      this.setState({organization: response.data});
    }
  }

  async refreshResourceList() {
    const response = await this.callAPIAsync('users/resources');
    if (response) {
      const responseData = response.data;
      responseData.formGroups = {};
      responseData.forms.forEach((x)=> responseData.formGroups[x.groupName] ? responseData.formGroups[x.groupName].push(x) : responseData.formGroups[x.groupName] = [x]);
      this.setState({resources: responseData});
    }
  }

  onSignIn(googleUser) {
    const profile = googleUser.getBasicProfile();
    console.log('ID: ' + profile.getId()); // Do not send to your backend! Use an ID token instead.
    console.log('Name: ' + profile.getName());
    console.log('Image URL: ' + profile.getImageUrl());
    console.log('Email: ' + profile.getEmail()); // This is null if the 'email' scope is not present.

    const idToken = googleUser.getAuthResponse().id_token;

    instance.setState({authUser: googleUser});
    instance.setState({authUserEmail: profile.getEmail()});
    instance.setState({redirectPath: '/'});

    instance.refresh();
  }

  async refresh() {
    if (instance.state.authUser) {
      try {
        await this.refreshUser();
        await this.refreshOrganization();
        await this.refreshResourceList();
      } catch (err) {
        if (err && err.response) {
          this.setState({lastError: err.response.data});
          this.setState({lastErrorCode: err.response.status});
        }
      }
    } else {
      this.setState({redirectPath: '/signin'});
    }
  }

  async componentDidMount(props) {
    await this.refresh(props);
  }

  HandleRedirect() {
    if (this.state.redirectPath) {
      return <Redirect push to="{this.state.redirectPath}" />;
    }
  }

  handleClick(e) {
    // e.preventDefault();
    // console.log('The link was clicked: '+JSON.stringify(e));

    if (e && e.includes('http')) {
      window.open(e, '_blank');
    } else {
      this.setState({redirectPath: e});
    }
  }


  render() {
    const userElement = null;
    const organizationElement = null;
    const resourcesElement = null;

    const groupNames = this.state.resources && this.state.resources.formGroups ? Object.keys(this.state.resources.formGroups) : [];
    const userGroups = this.state.resources && this.state.resources.userGroups ? this.state.resources.userGroups : [];

    function HandleRedirect() {
      if (instance.state.redirectPath) {
        const path = instance.state.redirectPath;
        instance.state.redirectPath = null;
        return <Redirect push to={path} />;
      }

      return null;
    }

    return (
      <div className="App">
        <div>
          <HandleRedirect />

          <MenuBar organization={instance.state.organization} authUser={instance.state.authUser} user={instance.state.user} />
          <div className="content-body">
            <Switch>
              <Route path="/signin">
                <Signin onSignIn={instance.onSignIn} isSignedIn={true}></Signin>
              </Route>
              <Route path="/forms/:formId" children={<UserForm resources={instance.state.resources} user={instance.state.user} authUser={instance.state.authUser} />}></Route>
              <Route path="/subordinates/:subordinateId/forms/:formId/submission/:submissionDate" children={<SubordinateFormValue resources={instance.state.resources} user={instance.state.user} authUser={instance.state.authUser} />}></Route>
              <Route path="/subordinates/:subordinateId/forms/:formId" children={<SubordinateForm resources={instance.state.resources} user={instance.state.user} authUser={instance.state.authUser} />}></Route>
              <Route path="/subordinates/:subordinateId" children={<Subordinate resources={instance.state.resources} user={instance.state.user} authUser={instance.state.authUser} />}></Route>

              <Route path="/reports/recentforms/allvalues" children={<RecentForms resources={instance.state.resources} user={instance.state.user} authUser={instance.state.authUser} isIncludeAllFormValues={true} />}></Route>
              <Route path="/reports/recentforms" children={<RecentForms resources={instance.state.resources} user={instance.state.user} authUser={instance.state.authUser} isIncludeAllFormValues={false} />}></Route>
              <Route path="/reports/recentformsGrid" children={<RecentFormsGrid resources={instance.state.resources} user={instance.state.user} authUser={instance.state.authUser} />}></Route>
              <Route path="/reports/dashboard" children={<Dashboard resources={instance.state.resources} user={instance.state.user} authUser={instance.state.authUser} />}></Route>


              <Route path="/">
                {this.state.lastError ? (
                <div style={{'margin': '10px'}}><br />Unable to continue: {this.state.lastError}
                  <br /><br />{this.state.lastErrorCode === 401 ? (
                    <div>Your Google login <strong>{instance.state.authUserEmail}</strong> is not authorized. Please contact your manager to have your account added, and then try again.<br /><br /><center><img src="sad_pug.jpg" style={{'max-width': '98%'}}></img></center>
                      <div style={{'text-align': 'right'}}>...sorry.</div>
                      <Signin onSignIn={instance.onSignIn} isSignedIn={false}></Signin></div>
                    ) : ''}</div>
                ) : this.state.resources ? (
              <div className="resources">

                {this.state.resources && this.state.resources.reports && this.state.resources.reports.length > 0 ? (
                  <div className="reports">
                    <div className="ButtonGroupTitle"><IconPieChart style={{'vertical-align': 'middle', 'margin-right': '5px'}}></IconPieChart>Reports</div>
                    <div className="reportsList">
                      <ButtonGroup className={'listItems'} orientation="vertical" color="secondary" aria-label="vertical contained primary button group" variant="text">
                        {this.state.resources.reports.map((x) =>
                          (
                            <Button key={x.id} onClick={() => this.handleClick(x.uri)}>{x.name}</Button>
                          // <Button key={x.id} class="report"><a href={x.uri}>{x.name}</a></Button>
                          ),
                        )}
                      </ButtonGroup>
                    </div>
                  </div>
                  ) : '' }

                {groupNames.map((groupName) =>
                  (
                    // Forms
                    <div className="forms" key={groupName}>
                      <div className="ButtonGroupTitle"><IconListAlt style={{'vertical-align': 'middle', 'margin-right': '5px'}}></IconListAlt>{groupName}</div>
                      <ButtonGroup className={'listItems'} orientation="vertical" color="primary" aria-label="vertical contained primary button group" variant="text">
                        {this.state.resources.formGroups[groupName].map((x) =>
                          (
                            <div key={x.id} >
                              <Button onClick={() => this.handleClick(`/forms/${x.id}`)}>
                                {x.isShared ? (
                                  <IconGroup style={{'margin-right': '5px'}}></IconGroup>
                                ) : ''}
                                {x.name}</Button>
                            </div>
                          ),
                        )}
                      </ButtonGroup>
                    </div>
                  ),
                )}

                {userGroups.map((userGroup) =>
                  (
                    // Subordinate user groups
                    <div className="forms" key={userGroup.id}>
                      <div className="ButtonGroupTitle"><IconPerson style={{'vertical-align': 'middle', 'margin-right': '5px'}}></IconPerson>{userGroup.name}</div>
                      <ButtonGroup className={'listItems'} orientation="vertical" color="primary" aria-label="vertical contained primary button group" variant="text">
                        {userGroup.userReports.map((user) => {
                          const letterGrade = user._report ? ' ('+user._report.letterGrade+') - '+user._report.count : null;
                          const grade = user._report ? ' - ' + (Math.round(user._report.grade * 10) / 10) + '%' : null;
                          return (
                            <div key={user.id} >
                              <Button onClick={() => this.handleClick(`/subordinates/${user._userId}`)}>{user.firstName+' '+user.lastName}{grade}{letterGrade}</Button>
                            </div>
                          );
                        })}
                      </ButtonGroup>
                    </div>
                  ),
                )}


              </div>
            ) : (
              <div>Loading resources...</div>
            )}


              </Route>
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
