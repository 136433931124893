import React from 'react';
// import {makeStyles, withStyles} from '@material-ui/core/styles';
import {AppBar, Toolbar, Typography, IconButton, Avatar} from '@material-ui/core';


let instance = null;

// const useStyles = makeStyles((theme) => ({
//   root: {
//     flexGrow: 1,
//   },
//   menuButton: {
//     marginRight: theme.spacing(2),
//   },
//   title: {
//     flexGrow: 1,
//   },
// }));


class MenuBar extends React.Component {
  constructor() {
    super();

    this.state = {};
    instance = this;
  }


  render() {
    // const {classes} = this.props;
    return (
      <div className={'root'}>
        <div style={{'height': '38px'}}></div>
        <AppBar position="fixed">
          <Toolbar>
            <IconButton edge="start" className={'menuButton'} color="inherit" aria-label="menu">
              {/* <MenuIcon /> */}
            </IconButton>
            <Typography variant="h6" className={'title'}>
              {instance.props.organization ? instance.props.organization.name : 'AdvancePsych'}
              {instance.props.organization ? (
                <img src={instance.props.organization.logoImage} style={{'verticalAlign': 'middle', 'maxHeight': '50px', 'paddingLeft': '10px'}}></img>
              ) : ''}
            </Typography>
            {/* <Button color="inherit">Login</Button> */}
            {instance.props.authUser || (instance.props.user && instance.props.user.firstName) ? (
              <div style={{'display': 'inline-block', 'flexGrow': '1', 'flexDirection': 'row-reverse', 'display': 'flex'}}>
                <Avatar alt={instance.props.user.firstName} src={instance.props.authUser.getBasicProfile().getImageUrl()} />
              </div>
            ) : ''}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default MenuBar; // withStyles(useStyles)(MenuBar);
